import { IMenuItem, ISubMenu } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';
import DropdownMenu from './DropdownMenu';

import styles from './Menu.module.scss';

import Submenu from './Submenu';

interface MenuProps {
  data: IMenuResponse | null;
}

export interface IMenuResponse {
  content: Array<IMenuItem | ISubMenu>;
}

const MenuItemTitle = ({ menuItem }: { menuItem: IMenuItem | ISubMenu }) => (
  <>
    {menuItem.title}
    {menuItem.type === 'submenu' && (
      <svg className={styles.dropdownIcon}>
        <use
          xlinkHref='/media/angle_down.svg#angleDown'
          href='/media/angle_down.svg#angleDown'
        />
      </svg>
    )}
  </>
);

const Menu = ({ data }: MenuProps) => {
  const pathname = usePathname();
  const menuRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    (menuRef.current as HTMLUListElement).classList.add(styles.disabled); // remove menu hover and hide it on route change
    setTimeout(() => {
      (menuRef.current as HTMLUListElement).classList.remove(styles.disabled);
    }, 1);
  }, [pathname]);

  return (
    <nav className={styles.menu}>
      <ul
        className={styles.menu__list}
        ref={menuRef}
      >
        {data?.content?.map((item) => (
          <li
            id={`headedNavItem-${item.id}`}
            className={styles.menu__item}
            key={`${item.id}/${item.slug}`}
          >
            {typeof item.slug !== 'undefined' ? (
              <Link
                href={`/${item.slug}`}
                className={classnames(styles.menuItem, {
                  [styles.active]: pathname === `/${item.slug}`,
                })}
              >
                <MenuItemTitle menuItem={item} />
              </Link>
            ) : (
              <button
                type='button'
                className={classnames(styles.menuItem, styles.linkless, {
                  [styles.active]: pathname === `/${item.slug}`,
                  [styles.dropdownButton]: item.title !== 'Services',
                })}
              >
                <MenuItemTitle menuItem={item} />
                {item.title === 'Services' ? (
                  <Submenu
                    data={item as ISubMenu}
                    className={styles.submenu}
                  />
                ) : (
                  <DropdownMenu
                    className={styles.dropdown}
                    data={item as ISubMenu}
                  />
                )}
              </button>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Menu;
