import { ISubMenu } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { theme } from '../../../store/theme';
import Img from '../../Img';
import styles from './DropdownMenu.module.scss';

type DropdownMenuProps = {
  className?: string,
  data: ISubMenu
};
const DropdownMenu = ({ className, data }: DropdownMenuProps) => {
  const appTheme = useAtomValue(theme);

  return (
    <div
      className={classnames(styles.dropdown, className)}
    >
      {data.content.map(menuItem => (
        <Link
          className={styles.link}
          key={menuItem.id}
          href={`/${menuItem.slug}`}
        >
          <div className={styles.link__icon}>
            <Img
              src={(menuItem as ISubMenu).menuIcon?.[appTheme].url || '/'}
              alt={menuItem.title}
            />
          </div>
          {menuItem.title}
        </Link>
      ))}
    </div>
  );
};

export default DropdownMenu;
