import styles from './Footer.module.scss';
import Img from './Img';

const AddressBlock = ({ address }: { address: any }) => (
  <div className={styles.address}>
    <p className={styles.address__city}>
      <Img
        className={styles.flag}
        src={address.flag.url}
        alt={address.flag.alternativeText}
        width={18}
        height={12}
      />
      {address.city}
    </p>
    <div className={styles.info}>
      <p className={styles.address__text}>{address.street}</p>
      <a
        className={styles.address__text}
        href={`mailto:${address.email}`}
      >
        {address.email}
      </a>
      <a
        className={styles.address__text}
        href={`tel:${address.phone}`}
      >
        {address.phone}
      </a>
    </div>
  </div>
);

export default AddressBlock;
