import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

type PortalPropsType = {
  children: ReactNode;
  className?: string;
};

const Portal = ({ children, className }: PortalPropsType) => {
  const [root, setRoot] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const item = document.createElement('div');
    document.body.appendChild(item);
    className && item.classList.add(...className.split(' '));
    setRoot(item);

    return function close() {
      document.body.removeChild(item);
    };
  }, [className]);

  return root && ReactDOM.createPortal(children, root);
};

export default Portal;
