import { ISubMenu } from '@sapientpro/sapientpro-data-models';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import React from 'react';
import styles from './SubmenuItem.module.scss';
import Img from '../Img';
import { theme, Theme } from '../../store/theme';

type SubmenuItemProps = {
  submenuItem: ISubMenu;
};
const SubmenuItem = ({ submenuItem }: SubmenuItemProps) => {
  const appTheme = useAtomValue<Theme>(theme);

  return submenuItem?.slug ? (
    <Link
      href={`/${submenuItem.slug}`}
      className={styles.submenuItem}
    >
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          {submenuItem.menuIcon && (
            <Img
              src={submenuItem.menuIcon[appTheme].url}
              alt={submenuItem.menuIcon[appTheme].alternativeText || 'menu icon'}
            />
          )}
        </div>
      </div>
      <div className={styles.submenu__text}>
        <p className={styles.title}>{submenuItem.title}</p>
      </div>
    </Link>
  ) : (
    <div className={styles.submenuItem}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          {submenuItem.menuIcon && (
            <Img
              src={submenuItem.menuIcon[appTheme].url}
              alt={submenuItem.menuIcon[appTheme].alternativeText || 'menu icon'}
            />
          )}
        </div>
      </div>
      <div className={styles.submenu__text}>
        <p className={styles.title}>{submenuItem.title}</p>
      </div>
    </div>
  );
};

export default SubmenuItem;
