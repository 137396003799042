import classnames from 'classnames';

import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { getCookie, setCookie } from '../../../cookiesControls';

import { theme, Theme } from '../../../store/theme';
import Img from '../../Img';
import styles from './ThemeToggle.module.scss';

const ThemeToggle = () => {
  const [appTheme, setAppTheme] = useAtom(theme);

  const cookieTheme = getCookie('theme');

  const changeTheme = () => {
    const changedTheme = !cookieTheme
      ? (appTheme === Theme.DARK ? Theme.LIGHT : Theme.DARK)
      : cookieTheme === Theme.DARK ? Theme.LIGHT : Theme.DARK;

    setCookie('theme', changedTheme, 365);
    setAppTheme(changedTheme);
  };

  useEffect(() => {
    if (cookieTheme) {
      setAppTheme(cookieTheme as Theme);
      document.documentElement.dataset.theme = cookieTheme;
    }
  }, []);

  return (
    <button
      type='button'
      onClick={changeTheme}
      className={classnames(styles.toggle, {
        [styles.toggle_light]: appTheme === Theme.LIGHT,
      })}
    >
      <div className={styles.circle}>
        <Img
          alt='particles'
          src={`/media/themeToggle/themeParticles-${appTheme}.webp`}
          className={styles.particles}
          width={16}
          height={16}
        />
        {appTheme === Theme.DARK && (
          <Img
            alt='shadow'
            src='/media/themeToggle/themeShadow-dark.svg'
            className={styles.particles__shadow}
            width={16}
            height={16}
          />
        )}
      </div>
    </button>
  );
};

export default ThemeToggle;
