import classnames from 'classnames';
import Link from 'next/link';
import styles from './Chip.module.scss';

type CommonProps = {
  title: string,
  className?: string,
  active?: boolean,
  disabled?: boolean,
  scroll?: boolean,
};

type ConditionalProps =
  | {
    url: string,
    onClick?: never,
  }
  | {
    url?: never,
    onClick: () => void,
  };

type ChipProps = CommonProps & ConditionalProps;

const Chip = ({
  url, onClick, title, className, active, disabled, scroll,
}: ChipProps) => {
  if (url) {
    return (
      <Link
        href={url}
        className={classnames(styles.chip, className, {
          [styles.active]: active,
          [styles.disabled]: disabled,
        })}
        scroll={scroll}
      >
        {title}
      </Link>
    );
  } else {
    return (
      <button
        type='button'
        aria-label={`Filter by ${title} topic`}
        className={classnames(styles.chip, className, {
          [styles.active]: active,
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onClick={onClick}
      >
        {title}
      </button>
    );
  }
};

export default Chip;
