import { IPostInfo } from '@sapientpro/sapientpro-data-models';
import classnames from 'classnames';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import AnimatedTitle, { AnimationType } from '../../components/AnimatedTitle';
import Img from '../../components/Img';
import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import styles from './ArticleCard.module.scss';
import { ALL_CATEGORY } from '../../../constants';

type ArticleCardProps = {
  className?: string,
  data: IPostInfo,
  verticalLayout?: boolean,
  withoutDescription?: boolean,
  minifiedLayout?: boolean,
  withoutCategory?: boolean,
  searchView?: boolean,
  filteredTag?: string,
  tagScroll?: boolean,
};
const ArticleCard = ({
  className,
  data,
  verticalLayout,
  withoutDescription,
  minifiedLayout,
  withoutCategory,
  searchView = false,
  filteredTag,
  tagScroll = true,
}: ArticleCardProps) => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const showedTag = data.tags.find(tag => tag.slug?.toLowerCase() === filteredTag?.toLowerCase()) || data.tags[0];

  return (
    <article className={classnames(styles.article, className, {
      [styles.verticalLayout]: verticalLayout,
      [styles.minifiedLayout]: minifiedLayout,
      [styles.article_searchView]: searchView,
    })}
    >
      <Link
        href={`/blog/${data.slug}`}
        className={styles.image}
        aria-label={data.title}
      >
        <Img
          src={data.backgroundImage?.url}
          alt={data.backgroundImage.alternativeText || 'background image'}
          withPreloader
        />
      </Link>
      <div className={styles.content}>
        {!withoutCategory && (
          <Link
            className={styles.category}
            href={`/blog/${ALL_CATEGORY.slug}/${showedTag.slug}`}
            scroll={tagScroll}
          >
            {showedTag.title}
          </Link>
        )}
        <Link
          href={`/blog/${data.slug}`}
          className={styles.title}
        >
          <AnimatedTitle
            title={data.title}
            animationType={AnimationType.NONE}
          />
        </Link>
        {!withoutDescription && data.description && (
          <p
            className={styles.text}
          >
            {data.description?.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, ' ')}
          </p>
        )}
        <footer className={styles.info}>
          <Link
            href={`/blog/author/${data.author.slug}`}
            className={styles.author}
          >
            {data.author.pseudonym.split(' ')[0]}
          </Link>
          <time className={styles.date}>{data.publishDate}</time>
          <p className={styles.readtime}>
            {`${data.readTime} min ${deviceSize !== ScreenSize.MOBILE ? 'read' : ''}`}
          </p>
        </footer>
      </div>
    </article>
  );
};

export default ArticleCard;
