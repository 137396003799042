import {
  ICasesListResponse, IIndustry, IPostsListResponse, IService,
} from '@sapientpro/sapientpro-data-models';

export type SearchResults = {
  posts?: IPostsListResponse,
  industries: IIndustry[],
  services: IService[],
  cases?: ICasesListResponse,
};

export enum SearchType {
  POSTS_LOADED = 'add_posts',
  LOAD_MORE_POSTS = 'load_posts',
  INDUSTRIES = 'add_industries',
  SERVICES = 'add_services',
  CASES_LOADED = 'add_cases',
  LOAD_MORE_CASES = 'load_cases',
  CLEAR = 'clear',
}

export type SearchAction = {
  type: SearchType,
  data?: IPostsListResponse | IIndustry[] | IService[] | ICasesListResponse
};

export const initialSearchResults: SearchResults = {
  posts: undefined,
  industries: [],
  services: [],
  cases: undefined,
};

export default function searchReducer(state: SearchResults, action: SearchAction): SearchResults {
  switch (action.type) {
    case SearchType.POSTS_LOADED: {
      return {
        ...state,
        posts: action.data as IPostsListResponse,
      };
    }
    case SearchType.LOAD_MORE_POSTS: {
      return {
        ...state,
        posts: {
          posts: [...(state.posts as IPostsListResponse).posts, ...(action.data as IPostsListResponse).posts],
          pagination: (action.data as IPostsListResponse).pagination,
        } as IPostsListResponse,
      };
    }
    case SearchType.INDUSTRIES: {
      return {
        ...state,
        industries: action.data as IIndustry[],
      };
    }
    case SearchType.SERVICES: {
      return {
        ...state,
        services: action.data as IService[],
      };
    }
    case SearchType.CASES_LOADED: {
      return {
        ...state,
        cases: action.data as ICasesListResponse,
      };
    }
    case SearchType.LOAD_MORE_CASES: {
      return {
        ...state,
        cases: {
          cases: [...(state.cases as ICasesListResponse).cases, ...(action.data as ICasesListResponse).cases],
          pagination: (action.data as ICasesListResponse).pagination,
        } as ICasesListResponse,
      };
    }
    case SearchType.CLEAR: {
      return initialSearchResults;
    }
    default: {
      throw Error('Unknown action.');
    }
  }
}
