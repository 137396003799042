import { IPostsListResponse } from '@sapientpro/sapientpro-data-models';
import { ICasesListResponse } from '@sapientpro/sapientpro-data-models/src/case/case';
import { IIndustry } from '@sapientpro/sapientpro-data-models/src/industry/industry';
import { IService } from '@sapientpro/sapientpro-data-models/src/service/service';
import client from '../../apiClient';

export const getPosts = async (searchQuery: string, offset: number, limit: number) => {
  try {
    return await client.get<IPostsListResponse>(
      `posts?${new URLSearchParams(JSON.parse(JSON.stringify({
        search: searchQuery, offset, limit, us: false,
      })))}`,
      {
        cache: 'no-cache',
      },
    );
  } catch (e) {
    console.error(e.message);
  }
};

export const getServices = async (searchQuery: string) => {
  try {
    return await client.get<IIndustry[]>(`services?${new URLSearchParams({ search: searchQuery })}`, {
      cache: 'no-cache',
    });
  } catch (e) {
    console.error(e.message);
  }
};

export const getSubservices = async (searchQuery: string) => {
  try {
    return await client.get<IService[]>(`sub-services?${new URLSearchParams({ search: searchQuery })}`, {
      cache: 'no-cache',
    });
  } catch (e) {
    console.error(e.message);
  }
};

export const getCases = async (searchQuery: string, offset: number, limit: number) => {
  try {
    return await client.get<ICasesListResponse>(
      `cases/list?${new URLSearchParams(JSON.parse(JSON.stringify({ search: searchQuery, offset, limit })))}`,
      {
        cache: 'no-cache',
      },
    );
  } catch (e) {
    console.error(e.message);
  }
};
